import { gql } from '@apollo/client';

export const VISIBLE_FLASH_MESSAGES = gql`
  query visibleFlashMessages {
    visibleFlashMessages @client {
      visibleMessages {
        id
        type
        heading
        text
        code
        renderDescription
        data
      }
    }
  }
`;
