import React from 'react';
import { MESSAGE_TYPES } from '@/lib/constants/messages';
import { FlashMessage } from '@/controllers/platform/flashMessages/flashMessages.typedefs';
import { IconSuccess } from '@/components/ui/icons/IconSuccess';
import { IconError } from '@/components/ui/icons/IconError';
import { IconInfo } from '@/components/ui/icons/IconInfo';
import { AchievementFlashMessageItem } from '@/components/ui/flashMessage/AchievementFlashMessage';
import { StreakFlashMessageItem } from '@/components/ui/flashMessage/StreakFlashMessage';
import { FCIcon } from '@/components/ui/icons/typedefs';
import { FlashMessageProps } from '@/components/ui/flashMessage/typedefs';
import { DefaultFlashMessageItem } from '@/components/ui/flashMessage/DefaultFlashMessage';
import { WarningIcon } from '@/components/ui/icons/WarningIcon';
import { DEFAULT_CLOSE_DELAY, MIN_TIME_MS_PER_CHAR } from '@/components/ui/flashMessage/constants';

export const getIconByType = (type: FlashMessage['type']): FCIcon => {
  switch (type) {
    case MESSAGE_TYPES.success:
      return IconSuccess;
    case MESSAGE_TYPES.warning:
      return WarningIcon;
    case MESSAGE_TYPES.error:
      return IconError;
    case MESSAGE_TYPES.info:
    default:
      return IconInfo;
  }
};

export const getFlashMessageItem = (
  type: FlashMessage['type'],
): React.FC<FlashMessageProps> => {
  switch (type) {
    case MESSAGE_TYPES.achievement:
      return AchievementFlashMessageItem;

    case MESSAGE_TYPES.streak:
      return StreakFlashMessageItem;

    default:
      return DefaultFlashMessageItem;
  }
};

export const getDelayTime = (
  messageDescription?: string,
): number => (
  Math.max(
    DEFAULT_CLOSE_DELAY,
    (messageDescription?.length || 0) * MIN_TIME_MS_PER_CHAR,
  )
);
