import React, { FC } from 'react';
import { cn } from '@/lib';
import { FlashMessageItemVariables } from '@/components/ui/flashMessage/typedefs';
import styles from './FlashMessageTimerLine.module.scss';

type Props = {
  closeDelay: number;
  isPaused?: boolean;
};

export const FlashMessageTimerLine: FC<Props> = ({
  closeDelay,
  isPaused,
}) => {
  const flashMessageItemVariables = {
    [FlashMessageItemVariables.CloseDelay]: `${closeDelay}ms`,
  } as React.CSSProperties;

  return (
    <div
      data-qa="flash-message-timer-line"
      className={cn(
        styles.timerLine,
        {
          [styles.paused]: isPaused,
        },
      )}
      style={flashMessageItemVariables}
    />
  );
};
