import React, {
  FC,
  useCallback,
  useRef,
  useState,
} from 'react';
import { cn } from '@/lib';
import { Button } from '@/components/ui/Button';
import { FlashMessageProps } from '@/components/ui/flashMessage/typedefs';
import { IconCross } from '@/components/ui/icons/IconCross';
import { DEFAULT_CLOSE_DELAY_AFTER_HOVER } from '@/components/ui/flashMessage/constants';
import { FlashMessageTimerLine } from '@/components/ui/flashMessage/FlashMessageTimerLine';
import styles from './FlashMessageBaseWrapper.module.scss';

type Props = FlashMessageProps & {
  className?: string;
  withTimerAnimation?: boolean;
};

export const FlashMessageBaseWrapper: FC<Props> = React.memo<Props>(
  ({
    children,
    message,
    updateTimer,
    clearTimer,
    setTimer,
    removeMessage,
    closeDelay,
    setCloseDelay,
    className,
    withTimerAnimation,
  }) => {
    const iteration = useRef(0);
    const [isActive, setIsActive] = useState(true);

    const handleMessageEnter = useCallback(
      () => {
        setIsActive(false);
        clearTimer();
      },
      [clearTimer],
    );

    const handleMessageLeave = useCallback(
      () => {
        iteration.current += 1;

        setIsActive(true);
        updateTimer(setTimer(DEFAULT_CLOSE_DELAY_AFTER_HOVER));
        setCloseDelay(DEFAULT_CLOSE_DELAY_AFTER_HOVER);
      },
      [
        updateTimer,
        setTimer,
        setCloseDelay,
      ],
    );

    const handleClose = useCallback(
      () => {
        removeMessage(message);
      },
      [
        removeMessage,
        message,
      ],
    );

    return (
      <div
        data-qa={`flash-message-${message.type}`}
        onTouchStart={handleMessageEnter}
        onTouchEnd={handleMessageLeave}
        onMouseEnter={handleMessageEnter}
        onMouseLeave={handleMessageLeave}
        className={cn(
          styles.message,
          className,
        )}
      >
        {children}

        <div className={styles.closeButtonWrapper}>
          <Button
            data-qa="flash-message-close-button"
            onClick={handleClose}
            className={styles.closeButton}
            RightIcon={IconCross}
            mode={Button.mode.TransparentLight}
            size={Button.size.Small}
          />
        </div>

        {withTimerAnimation && (
          <FlashMessageTimerLine
            key={`${message.id}_${iteration.current}`}
            closeDelay={closeDelay}
            isPaused={!isActive}
          />
        )}
      </div>
    );
  },
);
