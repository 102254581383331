import React from 'react';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { FCIcon } from '@/components/ui/icons/typedefs';

export const IconConfettiBackgroundOrange: FCIcon = (props) => (
  <BaseIcon
    width="408"
    height="136"
    viewBox="0 0 408 136"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill="#fec132">
      <path d="m33.8578 24c-3.4746 7.1912-9.8578 5.6829-9.8578 5.6829.1874 1.72.4708 3.4288.849 5.1191 0 0 10.8169.579 13.2853-9.1413-.9748-.2743-4.2765-1.6607-4.2765-1.6607z" />

      <path d="m160.887 5.99895c-4.353.98057-2.648-3.21498-2.648-3.21498.682-3.214981-4.287-4.35629-5.181.48225-.134 1.03859-.036 2.0937.286 3.09086.323.99716.863 1.91203 1.582 2.68003 1.624 1.60749 3.622 2.05759 7.033 1.46279 3.621-.3375 3.865-5.62619-1.072-4.50095z" />

      <path d="m324.519 10.4274c4.425-4.49962 7.044-4.54168 14.803-3.96696 1.326.0462 2.636-.2634 3.749-.88608s1.975-1.52773 2.465-2.59023c.244.16821 1.432 5.07428 1.432 5.07428-4.457 6.06949-11.387.70087-15.584 3.70059-1.122.799-2.538 2.3689-3.253 2.8035z" />
    </g>

    <path d="m390.621 120.148-2.319 6.141c-.681.246-4.037.675-8.382-4.606-.827-.998-1.427-3.071-.6-2.656 1.667.993 3.553 1.611 5.513 1.805 1.959.194 3.939-.04 5.788-.684z" fill="#ff7246" />
    <path d="m379.77 121.092c.226.916 1.793-7.171-3.231-10.66-.516-.353-4.167 4.036-4.716 4.518 2.923.965 7.463 4.405 7.947 6.142z" fill="#ff7246" />

    <path d="m360.676 19.3155c-4.353-.9806-2.648 3.215-2.648 3.215.682 3.215-4.287 4.3563-5.181-.4823-.134-1.0386-.036-2.0937.286-3.0908.323-.9972.863-1.9121 1.582-2.6801 1.624-1.6074 3.622-2.0576 7.033-1.4628 3.621.3376 3.865 5.6262-1.072 4.501z" fill="#e25544" />

    <path d="m125.542 24.2891c-6.165-2.8032-10.493 3.2497-16.231-5.3565-1.443-3.5711 2.443-5.8744 5.295-1.9998 2.64 3.571 10.116-.9107 14.756 2.9461 2.804 2.4104.64 6.4457-3.82 4.4102z" fill="#ff7246" />
    <path d="m338.613 137.412c7.393-3.553 11.594 1.204 11.594 1.204.951-1.422 1.813-2.893 2.581-4.405 0 0-8.418-6.533-16.628-.44.641.705 2.453 3.641 2.453 3.641z" fill="#e25544" />

    <path d="m395.386 66.6754c-.28.6435 1.038 5.6346 10.613 7.4867l-4.087 4.0808c-7.976-.8946-12.162-6.2782-12.541-9.7939 0 0 6.081-1.9463 6.015-1.7736z" fill="#fec132" />
  </BaseIcon>
);
