import React, { FC } from 'react';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';

export const WarningIcon: FC<any> = (props) => (
  <BaseIcon
    width="24"
    height="24"
    viewBox="-1 -2 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M0.86902 17.499C0.483055 18.1656 0.964115 19 1.73445 19H20.2656C21.0359 19 21.5169 18.1656 21.131 17.499L11.8654 1.49482C11.4803 0.829539 10.5197 0.829542 10.1346 1.49483L0.86902 17.499ZM12 16H10V14H12V16ZM12 12H10V8H12V12Z" fill="currentColor" />
  </BaseIcon>
);
