import React, { FC } from 'react';
import { cn } from '@/lib';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import { typography } from '@/components/ui/typography';
import { FlashMessageProps } from '@/components/ui/flashMessage/typedefs';
import { getIconByType } from '@/components/ui/flashMessage/FlashMessageItem/helpers';
import { FlashMessageBaseWrapper } from '@/components/ui/flashMessage/FlashMessageBaseWrapper';
import styles from './DefaultFlashMessageItem.module.scss';

type Props = FlashMessageProps;

export const DefaultFlashMessageItem: FC<Props> = React.memo<Props>(
  ({
    message,
    updateTimer,
    clearTimer,
    setTimer,
    removeMessage,
    closeDelay,
    setCloseDelay,
  }) => {
    const { t } = useTranslation([I18N_CODES.common]);

    const MessageIcon = getIconByType(message.type);

    const messageDescription = message.text
      || t(`${I18N_CODES.common}:${message.code}`);

    return (
      <FlashMessageBaseWrapper
        data-qa={`flash-message-${message.type}`}
        message={message}
        updateTimer={updateTimer}
        clearTimer={clearTimer}
        setTimer={setTimer}
        removeMessage={removeMessage}
        closeDelay={closeDelay}
        setCloseDelay={setCloseDelay}
        className={
          message.type in styles
            ? styles[message.type as keyof typeof styles]
            : ''
        }
        withTimerAnimation
      >
        <div
          data-qa="flash-message-icon"
          className={styles.iconWrapper}
        >
          <MessageIcon />
        </div>

        <div className={styles.contentWrapper}>
          <p
            data-qa="flash-message-heading"
            className={cn(
              typography.platformH3,
              styles.heading,
            )}
          >
            {message.heading || t(`${I18N_CODES.common}:messages.types.${message.type}`)}
          </p>

          <p
            data-qa="flash-message-content"
            className={cn(
              typography.platformTextSecondary,
              styles.description,
            )}
          >
            {
              message.renderDescription
                ? message.renderDescription()
                : messageDescription
            }
          </p>
        </div>
      </FlashMessageBaseWrapper>
    );
  },
);
