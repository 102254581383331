import React from 'react';
import { FCIcon } from '@/components/ui/icons/typedefs';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';

export const IconError: FCIcon = (props) => (
  <BaseIcon
    width="24"
    height="24"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0013 2.66699C8.62797 2.66699 2.66797 8.62699 2.66797 16.0003C2.66797 23.3737 8.62797 29.3337 16.0013 29.3337C23.3746 29.3337 29.3346 23.3737 29.3346 16.0003C29.3346 8.62699 23.3746 2.66699 16.0013 2.66699ZM22.668 20.787L20.788 22.667L16.0013 17.8803L11.2146 22.667L9.33464 20.787L14.1213 16.0003L9.33464 11.2137L11.2146 9.33366L16.0013 14.1203L20.788 9.33366L22.668 11.2137L17.8813 16.0003L22.668 20.787Z"
      fill="currentColor"
    />
  </BaseIcon>
);
